<template>
  <div class="flex flex-col justify-between">
    <List
      title="Roles"
      resource="appRoles"
      :basePath="basePath"
      :fields="fields"
      :request-params="{appId: appId, deletedOnly: true}"
      ref="list"
      track-by="appRoleId"
      on-row-click="details"
      infinity-scroll
      :initial-filter="filter"
      :search="true"
      search-placeholder="Search for a role by name or description"
      :filter-mapper="mapFilterToQuery"
    >
      <template v-slot:filters="props">
        <div class="form-row">
          <div class="form-col">
            <SelectInput
              name="customerId"
              label="customer"
              :options="customers"
            />
          </div>
        </div>
        <div class="form-row justify-end">
          <button @click.stop="props.reset()" class="btn-transparent mr-8">Reset</button>
          <button class="btn-primary">Filter</button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/components/auth/list/List';
import ModalNavigation from '@/mixins/ModalNavigation';
import SelectInput from '@/components/form/SelectInput';

export default {
    name: 'DeletedRolesList',
    components: {
      SelectInput,
      List,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      appId: function () {
        return this.$route.params.appId;
      }
    },
    data() {
      return {
        customers: [],
        filter: {
          appId: null,
        },
        fields: [
          {
            name: 'alias',
            sortField: 'alias',
            title: 'role name',
          },
          {
            name: 'description',
            title: 'description',
          },
        ],
      };
    },
    created() {
      this.$authDataProvider.getList('customers', {size: 999})
        .then(({content}) => {
          this.customers = content.filter(({deletedAt}) => !deletedAt)
            .map(({customerId: key, name: value}) => ({key, value}));
        })
        .catch((err) => this.notifyError(err.message));
    },
    methods: {
      mapFilterToQuery(values) {
        return Object.keys(values).reduce((query, key) => {
          const value = values[key];

          if (value) {
            query[key] = value;
          }

          return query;
        }, {});
      }
    },
    mounted() {
      this.$authDataProvider.getOne('apps', {id: this.appId})
        .then(app => {
          this.setActiveModalTitle(`${app.name} application roles`);
        })
        .catch(error => this.notifyError(error.message));
    }
  };
</script>
